module.exports = {
  keyList: {
    projectName: 'Tilli',
    projectUrl: 'tilli-web',
    apiURL: 'https://web.tillikids.com/tilli-api/',
    predictUrl: "https://web.tillikids.com/"
    // apiURL: 'http://localhost:8093/tilli-api/',
    // predictUrl: "http://localhost:8093/"
  },
};
