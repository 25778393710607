import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class LevelScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="row mx-0 center-page">
          <div className="col-2"> </div>
          <div className="col-8">  <p> LevelScreen </p> </div>
          <div className="col-2"> </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(LevelScreen);
